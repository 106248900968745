import { render, staticRenderFns } from "./bydestinationcountry.html?vue&type=template&id=21f87427&scoped=true&"
import script from "./bydestinationcountry.vue?vue&type=script&lang=js&"
export * from "./bydestinationcountry.vue?vue&type=script&lang=js&"
import style0 from "./bydestinationcountry.css?vue&type=style&index=0&id=21f87427&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21f87427",
  null
  
)

export default component.exports